import styled from "styled-components";

export const ChatComponentContainer = styled.div`
  width: 95%;
  max-width: 400px;
  margin-top: 16px;
`;

export const ChatContainer = styled.div`
  width: 100%;
  background-color: #000;
  border-radius: 5px;
  color: white;
  font-family: "Courier New", monospace;
`;

export const MessageList = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

export const MessageRow = styled.div`
  margin: 8px;
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px solid white;

  border-radius: 8px;
  padding: 8px;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const DateBox = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  color: gray;
  font-size: 0.8em;
`;

export const MessageText = styled.div`
  width: 100%;

  color: white;
  font-family: "Oswald";
  flex-wrap: wrap;
  word-wrap: break-word;
`;

export const ChatTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-family: "Oswald";
  font-weight: 600;
  text-decoration: none;
  color: white;
  margin-bottom: 16px;

  cursor: pointer;

  transition: all 0.3 ease-in;
  &:hover {
    text-decoration: underline;
  }
`;

export const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin: 8px;
`;

export const Input = styled.input`
  background-color: #222;
  color: white;
  border: 1px solid #555;
  padding: 5px;
  border-radius: 3px;
  margin-bottom: 5px;
`;

export const SendButton = styled.button`
  background-color: #444;
  color: white;
  padding: 5px;
  margin-bottom: 8px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background-color: #666;
  }
`;

export const DeleteButton = styled.button`
  margin-left: 8px;
  cursor: pointer;
`;

export const InstructionsContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "Oswald";
  font-weight: 600;
  text-decoration: none;
  color: black;
  text-align: center;
  margin-bottom: 16px;
  gap: 8px;

  transition: all 0.3 ease-in;
`;

export const ShowFulChatButton = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  opacity: 0.6;

  .chevron {
    width: 18px;
    transform: ${({ isExpanded }) =>
      isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
    transition: transform 0.3s ease;
  }
`;

export const ChevronIcon = styled.img`
  width: 18px;
  transform: translateX(180);
`;

export const FakeLink = styled.a`
  margin-left: 8px;
  color: black;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
